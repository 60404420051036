// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Portfolio(props) {
  console.log("Portfolio");
  console.log(props);
  console.log(props.items);
  if (Object.keys(props.items["As of"]).length !== 0){
    console.log(Object.values(props.items["As of"])[0]);
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
      return (
      <div>
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h3>PORTFOLIO DATA</h3>
              <p><b> AS OF</b> <span> {props.items["As of"][0]?props.items["As of"][0]:''} </span> </p>
            </div>
            <div className="row gy-5">
              <div className="col-lg-12">
                <div className="summary_detail pt-5">       
                  <div className="first_rw">
                    <div className="row g-4 summary_lft_txt">
                      <div className="col-lg-3">
                        <h5> subsidized 7-day yield* </h5>
                        <p>{props.items["Subsidized 7-Day Yield*"][0]?(props.items["Subsidized 7-Day Yield*"][0]*100).toFixed(2):''}%</p>
                      </div>
                      <div className="col-lg-3">
                        <h5> unsubsidized 7-day yield* </h5>
                        <p>{props.items["Unsubsidized 7-Day Yield*"][0]?(props.items["Unsubsidized 7-Day Yield*"][0]*100).toFixed(2):''}%</p>
                      </div>
                      <div className="col-lg-3">
                        <h5>weighted avg. maturity (WAM)**</h5>
                        <p>{props.items["Weighted Avg. Maturity (WAM)**"][0]?props.items["Weighted Avg. Maturity (WAM)**"][0]:''}</p>
                      </div>
                      <div className="col-lg-3">
                        <h5>weighted avg. life (WAL)***</h5>
                        <p>{props.items["Weighted Avg. Life (WAL)***"][0]?props.items["Weighted Avg. Life (WAL)***"][0]:''}</p>
                      </div>
                    </div>
                  </div>
                  <div className="second_rw">
                    <div className="row g-4 summary_lft_txt mt-1">
                      <div className="col-lg-6">
                        <h5> % of fund’s total assets invested in daily liquid assets </h5>
                        <p>{props.items["% of fund’s total assets invested in daily liquid assets"][0]?(props.items["% of fund’s total assets invested in daily liquid assets"][0]*100).toFixed(2):''}%</p>
                      </div>
                      <div className="col-lg-3">
                        <h5> % of fund’s total assets invested in weekly liquid assets </h5>
                        <p>{props.items["% of fund’s total assets invested in weekly liquid assets"][0]?(props.items["% of fund’s total assets invested in weekly liquid assets"][0]*100).toFixed(2):''}%</p>
                      </div>
                      <div className="col-lg-3">
                        <h5>daily net inflows and outflows****</h5>
                        <p>{props.items["Daily net inflows and outflows****"][0] ?formatter.format(props.items["Daily net inflows and outflows****"][0]):formatter.format(0)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>     
            </div>
            <div className="row g-4 g-lg-5 mt-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>          
              <div className="col-lg-12 ">
                {/* Tab Content */}
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <div className="d-flex align-items-center mt-4">
                      <i className="bi bi-check2" />
                      <h4>*7- Day Yield:</h4>
                    </div>
                    <p>The SEC yield for a money market fund is calculated by annualizing its daily income distributions for the previous 7 days. The 7-day yield quotation, rather than the total return quotation, more closely reflects the current earnings of the fund. The 7-Day Unsubsidized Yield represents what the yield would have been in the absence of temporary expense waivers or reimbursements.</p>
                    <div className="d-flex align-items-center mt-4">
                      <i className="bi bi-check2" />
                      <h4>**Weighted Average Life (WAL): </h4>
                    </div>
                    <p>WAL is based on the dollar-weighted average length of time until principal payments must be paid, taking into account any call options exercised by the issuer and any permissible maturity shortening features other than interest rate resets. For money market funds, the difference between WAM and WAL is that WAM takes into account interest rate resets and WAL does not.</p>
                    <div className="d-flex align-items-center mt-4">
                      <i className="bi bi-check2" />
                      <h4>***Weighted Average Maturity (WAM):</h4>
                    </div>
                    <p>WAM is a weighted average of all the maturities of the securities held in a fund. WAM is based on the dollar-weighted average length of time until principal payments must be paid. Depending on the types of securities held in a fund, certain maturity shortening devices (e.g., demand features, interest rate resets, and call options) may be taken into account when calculating the WAM.</p>
                    <div className="d-flex align-items-center mt-4">
                      <i className="bi bi-check2" />
                      <h4>****Daily Net Inflows and Outflows:</h4>
                    </div>
                    <p> Daily Net Flows measures the net of all cash inflows and cash outflows to and from the fund on a daily basis.</p>
                  </div>{/* End Tab 1 Content */}             
                </div>
              </div>
            </div>
          </div></section>{/* End Services Section */}
        {/* ======= On Focus Section ======= */}
        <section id="onfocus" className="onfocus">
          <div className="container aos-init aos-animate" data-aos="fade-up">
            <div className="col-lg-12">
              <div className="content d-flex flex-column justify-content-center h-100">
                <h3>FUND OVERVIEW</h3>
                <p className="fst-italic">
                  Investment Objective
                </p>
                <ul>
                  <li><i className="bi bi-check-circle" /> The Fund seeks to maximize current income to the extent consistent with the preservation of capital and the maintenance of liquidity.</li>
                </ul>
                <p className="fst-italic">
                  Principal Investment Strategy
                </p>
                <ul>
                  <li><i className="bi bi-check-circle" /> In pursuing its investment objective, the Fund will operate as a “government money market fund,” as such term is defined in or interpreted under Rule 2a-7 under the Investment Company Act of 1940, as amended (the “1940 Act”). Government money market funds are required to invest at least 99.5% of their total assets in: (i) cash; (ii) securities issued or guaranteed by the United States or certain U.S. government agencies or instrumentalities; and/or (iii) repurchase agreements that are fully collateralized (i.e., backed by cash or government securities). Government money market funds are exempt from requirements to impose liquidity fees and/or temporary redemption gates, however, government money market funds may elect to impose these fees. The Fund has elected to not impose liquidity fees or redemption gates at this time.</li>
                  <li><i className="bi bi-check-circle" /> As a government money market fund, the Fund values its securities using the amortized cost method. The Fund seeks to maintain a stable net asset value (“NAV”) of $1.00 per share. Under normal circumstances, the Fund will invest at least 80% of its assets (net assets plus the amount of any borrowing for investment purposes) in securities issued or guaranteed by the U.S. Treasury and/or repurchase agreements that are fully collateralized, or in other words, backed by such U.S. Treasury and/or repurchase agreements. The Fund will notify shareholders at least 60 days in advance of any change in this investment policy.</li>
                </ul>
                <p className="fst-italic">
                  Risk
                </p>
                <ul>
                  <li><i className="bi bi-check-circle" /> You could lose money by investing in the Fund. Although the Fund seeks to preserve the value of your investment at $1.00 per share, it cannot guarantee it will do so. An investment in the Fund is not a bank deposit and is not insured or guaranteed by the Federal Deposit Insurance Corporation or any other government agency. Please read the fund’s prospectus for a more detailed description of the risks.</li>
                </ul>
                {/* <a href="#" class="read-more align-self-start"><span>Read More</span><i class="bi bi-arrow-right"></i></a> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Portfolio;