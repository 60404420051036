import React from 'react';
import ReactDOM from 'react-dom';
import CookieConsent, { Cookies } from "react-cookie-consent";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from 'react-router-dom';
import logo from './img/logo.png';
import carousel from './img/hero-carousel/hero-carousel-1.png';
import './App.css';
import reportWebVitals from './reportWebVitals';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Summary from './Summary';
import Portfolio from './Portfolio';
import Performance from './Performance';
import Disclosures from './Disclosures';
import Distribution from './Distribution';
import loader from './assets/loader.gif';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';



const root = document.getElementById('root');
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      formContent:'',
      firstquestion:"No",
      secondquestion:false,
    };
    this.onChangeValue = this.onChangeValue.bind(this);
    this.firstqueforira = this.firstqueforira.bind(this);
    this.secqueforira = this.secqueforira.bind(this);
  }
  handleShow = e =>{
    e.preventDefault()
    this.setState({show : true,formContent : false,firstquestion:false,secondquestion:false})
  }
  handleClose = ()=>{
    this.setState({show : false,formContent : false,firstquestion:false,secondquestion:false})
  }
  onChangeValue(event) {
    console.log("onChangeValue func");
      console.log(event.target.value);
      console.log(event.target.name);
      this.setState({formContent : event.target.value,firstquestion:false,secondquestion:false}, () => {
          console.log(this.state.formContent, 'formContent');
          console.log(this.state.firstquestion, 'firstquestion');
          console.log(this.state.secondquestion, 'secondquestion');

        }); 
      if(event.target.value == "iraAcc"){
        console.log("inside check");
        this.setState({firstquestion:false}, () => {
          console.log(this.state.firstquestion, 'firstquestion');
        }); 
      }
    }
  firstqueforira(event) {
    console.log("firstqueforira func");
      console.log(event.target.value);
      console.log(event.target.name);
      this.setState({firstquestion : event.target.value}, () => {
          console.log(this.state.firstquestion, 'firstquestion');
        }); 
      console.log((this.state.firstquestion === "No" && this.state.firstquestion !== "Yes"&& this.state.firstquestion != false));
    }
  secqueforira(event) {
    console.log("sec func");
      console.log(event.target.value);
      console.log(event.target.name);
      this.setState({secondquestion : event.target.value}, () => {
          console.log(this.state.secondquestion, 'secondquestion');
        }); 
      
    }
  render () {
    return (
      <div>
      <header id="header" className="header" data-scrollto-offset={0}>
        <div className="container-fluid d-flex align-items-center justify-content-between menu_container">
          <a href="#" className="logo d-flex align-items-center scrollto me-auto me-lg-0">
            {/* Uncomment the line below if you also wish to use an image logo */}
            <img src={logo} alt="" />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/solutions">Solutions</a></li>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/resources">Resources</a></li>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/about-us">Company</a></li>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/contact">Contact</a></li>          
              <li><a className="nav-link scrollto" href="https://portal.northcapital.com/home">Login</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle d-none" />
          </nav>
        </div>
      </header>
      <section id="hero-animated" className="hero-animated d-flex align-items-center">
        <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
          <div className="row gy-4">
            <div className="col-lg-7 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center text-center">
              <h2>North Capital Treasury <span> Money Market Fund </span> </h2>
              <button class="mt-3 btn_banner" data-toggle="modal" data-target="#exampleModal" onClick={() => window.location.href = 'https://www.operaalts.com/login?partner=ncgxx'}>Invest</button>

            </div>
            <div className="col-lg-5 order-1 order-lg-2 text-center">
              <img src={carousel} alt="" className="img-fluid animated" />
            </div>
          </div>      
          {/* <img src="assets/img/hero-carousel/hero-carousel-1.svg" class="img-fluid animated">
          <h2>Welcome to <span>North Capital Treasury Money Market Fund</span></h2>     */}  
        </div>
      </section>
      <section id="features" className="features">
        <div className="container" data-aos="fade-up">
          <ul className="nav nav-tabs row gy-4 d-flex justify-content-center">
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a className="nav-link" href='https://app.hubspot.com/documents/4042879/view/48701570?accessId=03ca22'>
                <i className="bi bi-file-earmark-medical-fill" />
                <h4>Prospectus</h4>
              </a>
            </li>{/* End Tab 1 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a className="nav-link" href='https://app.hubspot.com/documents/4042879/view/48701460?accessId=6e8f1d'>
                <i className="bi bi-stickies-fill" />
                <h4>SAI</h4>
              </a>
            </li>{/* End Tab 2 Nav */}
            {/*<li className="nav-item col-6 col-md-4 col-lg-2" data-toggle="modal" data-target="#exampleModal">
              <a className="nav-link" onClick={this.handleShow}>
                <i className="bi bi-bag-fill" />
                <h4>How to Buy</h4>
              </a>
            </li>*/}{/* End Tab 3 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a className="nav-link" href='https://app.hubspot.com/documents/4042879/view/48701658?accessId=f38491'>
                <i className="bi bi-file-earmark-text-fill" />
                <h4>Summary Prospectus</h4>
              </a>
            </li>{/* End Tab 4 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a className="nav-link" href='https://app.hubspot.com/documents/4042879/view/63471013?accessId=449131'>
                <i className="bi bi-file-earmark-check-fill" />
                <h4>Fact Sheet</h4>
              </a>
            </li>{/* End Tab 5 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a className="nav-link" href='https://app.hubspot.com/documents/4042879/view/107288665?accessId=1ff625'>
                <i className="bi bi-info-circle" />
                <h4>Tax Information</h4>
              </a>
            </li>{/* End Tab 6 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a className="nav-link" href='https://app.hubspot.com/documents/4042879/view/81318942?accessId=648dfa'>
                <i className="bi bi-flag-fill" />
                <h4>Annual Report</h4>
              </a>
            </li>{/* End Tab 7 Nav */}
            <li className="nav-item col-6 col-md-4 col-lg-2">
              <a className="nav-link" href='https://app.hubspot.com/documents/4042879/view/81318960?accessId=afebce'>
                <i className="bi bi-flag-fill" />
                <h4>Semi-Annual Report</h4>
              </a>
            </li>{/* End Tab 8 Nav */}
          </ul>        
        </div>

        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              ...
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>

      </section>
      <Headers />
      <Modal
        size="lg"
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false} id='popup_htb'
      >
        <Modal.Header closeButton onClick={() => this.handleClose()}>
          <Modal.Title>How to Buy</Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-3'>
          <p>Complete and Submit an executed application along with any additional documentation required.</p>
          <p>There is a 3-step process to complete the account opening and initial purchase:</p>
          <ol>
          <li><p>Fill out the application type. **(For joint accounts, the first person to complete the application will be designated as “Individual,” the additional owner will fill out the “Joint Owner” section, and the account will not be opened until both parties have completed the application.)**</p></li>

          <li><p>US Bank processes your application, opens the account, and you will receive the account # with your processed application.</p></li>

          <li><p>Call 833-262-3863 (833-2-NCFUND) to place your initial purchase with the account number received with the processed application.</p></li>
          </ol>
          <strong>What type of account would you like to invest through?*</strong>
          <div onChange={this.onChangeValue} className="m-3">
          <p><input type="radio" name="accTypes" value="indAcc" class="me-2 mb-1" />Individual Account</p>
          <p><input type="radio" name="accTypes" value="jointAcc" class="me-2 mb-1" />Joint Account</p>
          <p><input type="radio" name="accTypes" value="entityAcc" class="me-2 mb-1" />Entity Account</p>
          <p><input type="radio" name="accTypes" value="minorAcc" class="me-2 mb-1" />Gift to Minor</p>
          <p><input type="radio" name="accTypes" value="trustAcc" class="me-2 mb-1" />Trust Account</p>
          <p><input type="radio" name="accTypes" value="cesaAcc"class="me-2 mb-1" />CESA Account</p>
          <p><input type="radio" name="accTypes" value="iraAcc" class="me-2 mb-1" />IRA Account</p>
          <p><input type="radio" name="accTypes" value="iraTrans" class="me-2 mb-1" />IRA Transfer</p>
          </div>
          <div>
            {this.state.formContent === "indAcc"?
              
                <a className='mb-2 ' style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }} href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=33431e51-fb31-48b7-a72e-c7c1e441bc91&amp;env=na3-eu1">submit</a>
              :''}
            {this.state.formContent === "jointAcc"?
              <div>
                <a className='mb-2 ' style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }} href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=562c7a1c-0ab0-4b89-8fe8-9370a06481f5&amp;env=na3-eu1">submit</a>
              </div>:''}
            {this.state.formContent=== "entityAcc"?
              <div>
                <a className='mb-2 ' style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }} href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=0004d518-8cce-4fde-9a73-7abf6070a739&amp;env=na3-eu1">submit</a>
              </div>:''}
            {this.state.formContent === "minorAcc"?
              <div>
                <a className='mb-2 ' style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }}  href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=c699b8cd-ad95-4b54-a5cd-1162331fc943&amp;env=na3-eu1">submit</a>
              </div>:''}
            {this.state.formContent === "iraTrans"?
              <div>
                <a className='mb-2 ' style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }} href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=d3b24932-ef3b-4d60-8615-b5c2ffd001ce&env=na3-eu1&acct=ce46e2d0-f7dd-48a8-b545-f815b01f6283">submit</a>
              </div>:''}
            {this.state.formContent === "trustAcc"?
              <div>
                <a className='mb-2 ' style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }} href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=0e77e6c6-e1fa-4ba5-b097-f468312b25a5&amp;env=na3-eu1">submit</a>
              </div>:''}
            {this.state.formContent === "cesaAcc"?
              <div>
                <a className='mb-2 ' style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }} href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=aa8bfc63-0a57-40fd-95d6-ad7bbc8d008e&amp;env=na3-eu1">submit</a>
              </div>:''}
            {this.state.formContent === "iraAcc"?
            <div>
              <div>
                <label>Are you married?<span>*</span></label>
                <div>(There are some cases where spousal consent is required for these types of accounts)</div>
                <div>
                  <ul className='m-2' onChange={this.firstqueforira} style={{listStyle:"none"}}>
                    <li className='ml-3'>
                      <input name="input_3" type="radio" value="Yes"/> <label className='ml-3'>Yes</label>
                    </li>
                    <li className='ml-2'>
                      <input name="input_3" type="radio" value="No" /> <label className='ml-3'>No</label>
                    </li>
                  </ul>
                </div>
              </div>
              {this.state.firstquestion != false ? <div>{(this.state.firstquestion === "No" && this.state.firstquestion !== "Yes")?
                  <div>
                    <a style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }}  href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=d3b24932-ef3b-4d60-8615-b5c2ffd001ce&amp;env=na3-eu1&amp;acct=ce46e2d0-f7dd-48a8-b545-f815b01f6283">submit</a> <br></br>
                    <a style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }}  href="">Contact us</a>
                  </div>:<div>
                          <p>
                          <label>Does your spouse need to provide consent according to the description below?<span>*</span></label>
                          <div>Spousal Consent: If you name someone other than or in addition to your spouse as primary beneficiary and reside in a community or marital property state, including AZ, CA, ID, LA, NV, NM, TX, WA, and WI, your spouse must consent.
                          </div>
                          <div>
                            <ul className='mt-2' onChange={this.secqueforira} style={{listStyle:"none"}}>
                            <li className='ml-3'>
                              <input name="input_4" type="radio" value="Yes"/> <label className='ml-3'>Yes</label>
                            </li>
                            <li className='ml-2'>
                              <input name="input_4" type="radio" value="No" /> <label className='ml-3'>No</label>
                            </li>
                            </ul>
                          </div>
                          </p>
                          <div>{this.state.secondquestion=="No"?<div>
                              <a  style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }}  href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=abe4e192-a3f7-40f1-b650-8dbdc4325ce9&env=na3-eu1">submit</a> <br></br>
                              {/*<a style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }}  href="">Contact us</a>*/}
                            </div>:this.state.secondquestion=="Yes"?<div>
                              <a  style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }}  href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=5da3c91e-ec4c-4eb1-afdb-8e6a26ac0970&env=na3-eu1">submit</a> <br></br>
                              {/*<a style={{color: "var(--color-white)", fontWeight: "500", fontSize: "16px", display: "inline-block", padding: "12px 40px", borderRadius: "5px", transition: "0.5s", marginTop: "10px", marginBottom: "10px", background: "rgba(var(--color-primary-dark-rgb), 0.9)" }}  href="">Contact us</a>*/}
                            </div>:''}
                        </div>
                      </div>
                }</div>:''}
              
              </div>
              :''}
              
          </div>
          <div>Contact us at 833-2-NCFUND (833-262-3863) or click the button below for questions and/or assistance.
          </div>
          <div className='text-center'><a href="mailto:northcapitalfunds@northcapital.com"> <button class="cus_btn"> Contact Us</button></a></div>
          
        </Modal.Body>
        <Modal.Footer className='m-3' style={{ display:"block"}}>
          <em>Past performance does not guarantee future results.</em>
          <p>You should carefully consider your investment objectives, risks, transaction costs and other expenses before deciding to invest in futures or other investments. North Capital Inc. is a Registered Investment Advisor and Commodities Trading Advisor. North Capital Private Securities Corporation is a registered broker dealer, member FINRA | SIPC. The North Capital Treasury Money Market Fund is distributed by North Capital Private Securities Corporation.</p>
        </Modal.Footer>
      </Modal>
      </div>
      
      
    );
    
  }
}
class Headers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      performance: [],
    };
  }
  componentDidMount() {
    fetch("https://s5alt7wyfxzxxsmn4nyocx7tyq0qoxct.lambda-url.us-west-1.on.aws/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: JSON.parse(result["North Capital Treasury Money Ma"]),
            distribution: JSON.parse(result["NC Money Market Fund -Distribut"]),
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  render () {
    if(!this.state.isLoaded){
      return(
        <div>
        <img src={loader} width="100%" />
        </div>
        );
    }
    return (
    <div>
    {this.state.isLoaded?<Summary items={this.state.items}/>:''}
    {this.state.isLoaded?<Portfolio items={this.state.items}/>:''}
    {this.state.isLoaded?<Performance items={this.state.items}/>:''}
    {this.state.isLoaded?<Distribution distribution={this.state.distribution} items={this.state.items}/>:''}
    <Disclosures />
    <Footer />
    <AcceptCookies />
    </div>
    );
  }
  
}
class Footer extends React.Component {
  render () {
    return (

      <footer id="footer" className="footer">
        <div className="footer-content">
          <div className="container">
            <div className="fr_links">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>SOLUTIONS</h4>
                  <ul>
                    <li><a href="https://www.northcapital.com/platform-technology">Platform Technology</a></li>
                    <li><a href="https://www.northcapital.com/escrow-custody">Escrow &amp; Custody</a></li>
                    <li><a href="https://www.ppex.com/?__hstc=242274077.f9085da52325e7550ecb44b12f77ca76.1666853848136.1666853848136.1666853848136.1&__hssc=242274077.1.1666853848136&__hsfp=2493300904">Secondary Trading</a></li>
                    <li><a href="https://advisory.northcapital.com/">Financial Advisory</a></li>
                    <li><a href="#">Privacy policy</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>RESOURCES</h4>
                  <ul>
                    <li><a href="https://blog.northcapital.com/resource-center">Blog</a></li>
                    <li><a href="https://transactapi.readme.io/docs">API Docs</a></li>
                    <li><a href="https://support.northcapital.com/knowledge">Help Center</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>COMPANY</h4>
                  <ul>
                    <li><a href="https://www.northcapital.com/about-us">About Us</a></li>
                    <li><a href="https://www.northcapital.com/careers">Careers</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>DISCLOSURES</h4>
                  <ul>
                    <li><a href="https://www.northcapital.com/disclosure-documents">Terms of Use</a></li>
                    <li> <a href="https://www.northcapital.com/disclosure-documents">Privacy Policy</a></li>
                    <li><a href="https://www.northcapital.com/disclosure-documents">Disclosure for CA Residents</a></li>
                    <li><a href="https://app.hubspot.com/documents/4042879/view/266393663?accessId=7d6a1d">Form CRS</a></li>
                    <li><a href="https://www.northcapital.com/disclosure-documents">View All Disclosures</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_cont_txt">
              <div className="row">
                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                  <div className="post-box">
                    <p><b> All investments involve risk, including the risk of loss of principal.</b>
                      You should carefully consider your investment objectives, risks, transaction costs and other expenses before deciding to invest in futures or other investments. North Capital Inc. is a Registered Investment Advisor and Commodities Trading Advisor. North Capital Private Securities Corporation is a registered broker dealer, member <b>FINRA | SIPC.</b></p>
                  </div>
                </div>
                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay={400}>
                  <div className="post-box">
                    <p>The relationship between North Capital Investment Technology, North Capital Inc., and North Capital Private Securities Corporation is described in our <b>Disclosure Documents</b> and <b>Code of Ethics</b>. Check the background of this firm on <b> FINRA's BrokerCheck </b> © 1999-2022. All Rights Reserved. <b>Submit a Complaint</b> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
    
  }
}
class AcceptCookies extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      showCookieBar: true
    };

  }

  render() {

    let testCookieName = "test"
    Cookies.remove(testCookieName)
    console.log("cookie: ", Cookies.get(testCookieName));
    Cookies.set(testCookieName, 500);
    console.log("cookie: ", Cookies.get(testCookieName));

    return (
      <div>
        {this.state.showCookieBar && (
          <CookieConsent
            location="bottom"
            buttonText="Ok"
            cookieName="myAwesomeCookieName3"
            style={{ background: "#2B373B"}}
            buttonStyle={{background: "#04D9FF",
                border: "0",
                fontWeight: "600",
                borderRadius: "0px",
                boxShadow: "none",
                color: "black",
                padding: "5px 10px",
                marginTop: "15px",
                marginRight: "15px",
                marginBottom: "15px",
                marginLeft: "15px"}}
            expires={150}
          >
            We use cookies to ensure that we give you the best experience on our website. By continuing to use our site you are accepting our use of cookies. <a href="https://app.hubspot.com/documents/4042879/view/71046062?accessId=25bd8f">Privacy policy</a>
            <span style={{ fontSize: "10px" }}>
              
            </span>
{/*            <a href="https://app.hubspot.com/documents/4042879/view/71046062?accessId=25bd8f" style={{
                float: "right",

                background: "#ffffff",
                border: "0",
                fontWeight: "600",
                borderRadius: "0px",
                boxShadow: "none",
                color: "black",
                padding: "5px 10px"
              }}
              onClick={() => {
                this.setState({ showCookieBar: false });
              }}
            >
              Privacy policy </a>*/}
          </CookieConsent>
        )}
      </div>
    );
  }
}
class Form extends React.Component {constructor(props) {
    super(props);
    this.state = {
      error: null,
      isFormLoaded: false,
      formitems: '',
    };
  }

  componentDidMount() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    var data = [];
    var res = '';
    const timeZones = ["GMT"];
    fetch("https://s5alt7wyfxzxxsmn4nyocx7tyq0qoxct.lambda-url.us-west-1.on.aws/")
      .then(res => res.json())
      .then(
        (result) => {
          res =   JSON.parse(result["NC T MM Fund - Daily Market Val"]);
          // console.log(res);
          Object.keys(res['Date']).map((formKey,i)=>{
            // console.log(res["Date"][formKey]);
            data[formKey] = {'Date':new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',timeZone: timeZones}).format(res["Date"][formKey]),'DailyMarketValue':parseFloat(res["Daily Market Value"][formKey]).toFixed(4),'DailyLiquidAssets':(String(res["Daily Liquid Assets"][formKey]).replace("%", ""))+'%','WeeklyLiquidAssets':(String(res["Weekly Liquid Assets"][formKey]).replace("%", ""))+'%','DailyNetShareholdercashflows':formatter.format(res["Daily Net Shareholder cashflows"][formKey])}

          });
          // console.log(data[0]);
          this.setState({
            isFormLoaded: true,
            formitems: data,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isFormLoaded: true,
            error
          });
        }
      )
  }
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  render () {
    if(!this.state.isFormLoaded){
      return(
        <div>
        <img src={loader} width="100%" />
        </div>
        );
    }
    const columns = [
        {
            name: 'Date',
            selector: row => row.Date,
        },
        {
            name: 'Daily Market Value',
            selector: row => row.DailyMarketValue,
        },
        {
            name: 'Daily Liquid Assets',
            selector: row => row.DailyLiquidAssets,
        },
        {
            name: 'Weekly Liquid Assets',
            selector: row => row.WeeklyLiquidAssets,
        },
        {
            name: 'Daily Net Shareholder cashflows',
            selector: row => row.DailyNetShareholdercashflows,
        },
    ];

    return (
      <div>
        {/* ======= Header ======= */}
        <header id="header" className="header" data-scrollto-offset={0}>
        <div className="container-fluid d-flex align-items-center justify-content-between menu_container">
          <a href="#" className="logo d-flex align-items-center scrollto me-auto me-lg-0">
            {/* Uncomment the line below if you also wish to use an image logo */}
            <img src={logo} alt="" />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/solutions">Solutions</a></li>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/resources">Resources</a></li>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/about-us">Company</a></li>
              <li><a className="nav-link scrollto" href="https://www.northcapital.com/contact">Contact</a></li>          
              <li><a className="nav-link scrollto" href="https://portal.northcapital.com/home">Login</a></li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle d-none" />
          </nav>
        </div>
      </header>{/* End Header */}
        <section id="hero-animated" className="hero-animated d-flex align-items-center">
          <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
            <div className="row gy-4 mt-5 mb-4">
              <div className="col-lg-12 order-2 order-lg-1 d-flex justify-content-center align-items-center text-center pt-5">
                <h3>North capital treasury money market fund daily market values, liquid assets, and net cash flows</h3>
              </div>
            </div>      
            {/* <img src="assets/img/hero-carousel/hero-carousel-1.svg" class="img-fluid animated">
        <h2>Welcome to <span>North Capital Treasury Money Market Fund</span></h2>     */}  
          </div>
        </section>
        <main id="main">
          {/* ======= Services Section ======= */}
          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="container">
                <div className="row">
                  <h2 className="text-center" />
                </div>
                <div className="row">
                  <div className="col-md-12 mt-5">
                  {console.log(this.state.formitems)}
                  <DataTable columns={columns} data={this.state.formitems} pagination
                striped
                dense
                noHeader/>
                    {/*<table id="example" className="table table-striped table-bordered dt-responsive nowrap" cellSpacing={0} width="100%">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Market Value</th>
                          <th>Daily Liquid Assets</th>
                          <th>Weekly Liquid Assets</th>
                          <th>Daily Net Shareholder cashflows</th>
                        </tr>
                      </thead>
                      <tbody>*/}
                        
                      {/*</tbody>
                    </table>*/}
                  </div>
                </div>
              </div>
            </div></section>{/* End Services Section */}
          <section id="featured-services" className="featured-services">
            <div className="container">
              <div className="row gy-4">
                <div className="col-xl-4 col-md-6 d-flex">
                  <div className="service-item position-relative">
                    <h4>ALL ACCOUNTS </h4>
                    <p><a href="https://funds.northcapital.com/open-an-account/">Open An Account</a></p>
                    <p><a href="https://funds.northcapital.com/all-accounts/">Learn More</a></p>
                  </div>
                </div>{/* End Service Item */}
                <div className="col-xl-4 col-md-6 d-flex">
                  <div className="service-item position-relative">
                    <h4>BROKER DEALER SERVICES </h4>
                    <p><a href="https://funds.northcapital.com/broker-dealer-services/#partnerships">Partnerships</a></p>
                    <p><a href="https://funds.northcapital.com/broker-dealer-services/#private">Private Funds</a></p>
                    <p><a href="https://funds.northcapital.com/broker-dealer-services/#issuers">Issuers</a></p>
                    <p><a href="https://funds.northcapital.com/broker-dealer-services/">Learn More</a></p>
                  </div>
                </div>{/* End Service Item */}
                <div className="col-xl-4 col-md-6 d-flex">
                  <div className="service-item position-relative">
                    <h4>INVESTMENT TECHNOLOGY</h4>
                    <p><a href="https://funds.northcapital.com/kyc-aml/">KYC/AML</a></p>
                    <p><a href="https://funds.northcapital.com/accreditation-verification/">Accreditation Verification</a></p>
                    <p><a href="https://funds.northcapital.com/technology/#payment">Payment Processing</a></p>
                    <p><a href="https://funds.northcapital.com/technology/">Learn More</a></p>
                    <p><a href="https://funds.northcapital.com/schedule-a-demo/" rel="noopener noreferrer" id="schedule" className="schedule pum-trigger" style={{cursor: 'pointer'}}>Schedule a Demo</a></p>
                  </div>
                </div>{/* End Service Item */}
                <div className="col-xl-4 col-md-6 d-flex">
                  <div className="service-item position-relative">
                    <h4>LEGAL DOCUMENTS</h4>
                    <p><a href="https://hubs.ly/H0xn6p10" rel="noopener noreferrer">Terms of Use</a></p>
                    <p><a href="https://hubs.ly/H0nTzdx0" rel="noopener noreferrer">Privacy Policy</a></p>
                    <p><a href="https://hubs.ly/H0phCs60" rel="noopener noreferrer">Supplemental Privacy Notice for California Residents</a></p>
                    <p><a href="https://funds.northcapital.com/library/#disclosure" rel="noopener noreferrer">Code of Ethics</a></p>
                    <p><a href="https://funds.northcapital.com/library/#disclosure" rel="noopener noreferrer">Disclosure Documents</a></p>
                    <p><a href="https://hubs.ly/H0sLQtR0" rel="noopener noreferrer">Disclosure Statement on Business Continuity Planning</a></p>
                    <p><a href="https://hubs.ly/H0yXHV40" rel="noopener noreferrer">Important Update – Form CRS</a></p>
                  </div>{/* End Service Item */}
                </div>
                <div className="col-xl-4 col-md-6 d-flex">
                  <div className="service-item position-relative">
                    <h4>ESCROW SERVICES</h4>
                    <p><a href="https://funds.northcapital.com/escrow-services/">Registered Funding Portals</a></p>
                    <p><a href="https://funds.northcapital.com/contact-us-escrow/">Open an Escrow Account</a></p>
                    <p><a href="https://funds.northcapital.com/escrow-services/">Learn More</a></p>
                  </div>
                </div>{/* End Service Item */}
                <div className="col-xl-4 col-md-6 d-flex">
                  <div className="service-item position-relative">
                    <h4>FOR MORE INFORMATION</h4>
                    <p><a href="https://support.northcapital.com/knowledge">View Our Knowledge Base</a></p>
                    <p><a href="https://funds.northcapital.com/resources/">View Our Resources</a></p>
                    <p><a href="https://funds.northcapital.com/blog" rel="noopener noreferrer">View Our Blog</a></p>
                    <p><a href="https://twitter.com/norcap" rel="noopener noreferrer">Follow Us on Twitter</a></p>
                    <p><a href="https://www.linkedin.com/company/north-capital-private-securities-corporation/" rel="noopener noreferrer">Find Us on LinkedIn</a></p>
                  </div>
                </div>{/* End Service Item */}
              </div>
            </div>
          </section>   
        </main>
        {/* ======= Footer ======= */}
        <footer id="footer" className="footer">
        <div className="footer-content">
          <div className="container">
            <div className="fr_links">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>SOLUTIONS</h4>
                  <ul>
                    <li><a href="https://www.northcapital.com/platform-technology">Platform Technology</a></li>
                    <li><a href="https://www.northcapital.com/escrow-custody">Escrow &amp; Custody</a></li>
                    <li><a href="https://www.ppex.com/?__hstc=242274077.f9085da52325e7550ecb44b12f77ca76.1666853848136.1666853848136.1666853848136.1&__hssc=242274077.1.1666853848136&__hsfp=2493300904">Secondary Trading</a></li>
                    <li><a href="https://advisory.northcapital.com/">Financial Advisory</a></li>
                    <li><a href="#">Privacy policy</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>RESOURCES</h4>
                  <ul>
                    <li><a href="https://blog.northcapital.com/resource-center">Blog</a></li>
                    <li><a href="https://transactapi.readme.io/docs">API Docs</a></li>
                    <li><a href="https://support.northcapital.com/knowledge">Help Center</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>COMPANY</h4>
                  <ul>
                    <li><a href="https://www.northcapital.com/about-us">About Us</a></li>
                    <li><a href="https://www.northcapital.com/careers">Careers</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>DISCLOSURES</h4>
                  <ul>
                    <li><a href="https://www.northcapital.com/disclosure-documents">Terms of Use</a></li>
                    <li> <a href="https://www.northcapital.com/disclosure-documents">Privacy Policy</a></li>
                    <li><a href="https://www.northcapital.com/disclosure-documents">Disclosure for CA Residents</a></li>
                    <li><a href="https://app.hubspot.com/documents/4042879/view/266393663?accessId=7d6a1d">Form CRS</a></li>
                    <li><a href="https://www.northcapital.com/disclosure-documents">View All Disclosures</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer_cont_txt">
              <div className="row">
                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                  <div className="post-box">
                    <p><b> All investments involve risk, including the risk of loss of principal.</b>
                      You should carefully consider your investment objectives, risks, transaction costs and other expenses before deciding to invest in futures or other investments. North Capital Inc. is a Registered Investment Advisor and Commodities Trading Advisor. North Capital Private Securities Corporation is a registered broker dealer, member <b>FINRA | SIPC.</b></p>
                  </div>
                </div>
                <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay={400}>
                  <div className="post-box">
                    <p>The relationship between North Capital Investment Technology, North Capital Inc., and North Capital Private Securities Corporation is described in our <b>Disclosure Documents</b> and <b>Code of Ethics</b>. Check the background of this firm on <b> FINRA's BrokerCheck </b> © 1999-2022. All Rights Reserved. <b>Submit a Complaint</b> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>{/* End Footer */}
      </div>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <Router>
          <Routes>
               <Route exact path='/' element={< Home />}></Route>
               <Route exact path='/nctmmf' element={< Form />}></Route>
               <Route path="*" element={< Home />} />
          </Routes>
     </Router>
   );
  }
}
ReactDOM.render(
  <React.StrictMode>
    <App />
    
  </React.StrictMode>,root
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
