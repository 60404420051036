import React from "react";
import avatar1 from './img/nc_logo_gren_fnd.png';
import avatar2 from './img/Jim_03-50x50.jpg';
import avatar3 from './img/Mike_03-50x50.jpg';

function Summary(props) {
  console.log(props);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const timeZones = ["GMT"];
  const date = new Date(props.items["Last Updated"][0]);
  const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timeZone: timeZones
};
  return(
    <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          {/* <div class="section-header">
          <h2>SUMMARY</h2>
        </div> */}
          <div className="dte_mark">
          <p>Last updated: <span> {date.toLocaleString('en-US', options)}</span></p>
          </div>
          <div className="row g-4 g-lg-5 bg_dark" data-aos="fade-up" data-aos-delay={200}>    
            <div className="col-lg-8">
              <div className="summary_detail">
                <h3>SUMMARY</h3>        
                <div className="first_rw">
                  <div className="row g-4 summary_lft_txt">
                    <div className="col-lg-4">
                      <h5> Symbol </h5>
                      <p>{props.items.Symbol[0]?props.items.Symbol[0]:''}</p>
                    </div>
                    <div className="col-lg-4">
                      <h5> Class </h5>
                      <p>{props.items.Class[0]?props.items.Class[0]:''}</p>
                    </div>
                    <div className="col-lg-4">
                      <h5>CUSIP</h5>
                      <p>{props.items.CUSIP[0]?props.items.CUSIP[0]:''}</p>
                    </div>
                  </div>
                </div>
                <div className="second_rw">
                  <div className="row g-4 summary_lft_txt mt-1">
                    <div className="col-lg-4">
                      <h5> Minimum Investment </h5>
                      <p>{props.items['Minimum Investment'][0]?props.items['Minimum Investment'][0]:''}</p>
                    </div>
                    <div className="col-lg-4">
                      <h5> Total Net Assets </h5>
                      <p> {formatter.format(props.items['Total Net Assets'][0]?props.items['Total Net Assets'][0]:'')}</p>
                    </div>
                    <div className="col-lg-4">
                      <h5>Daily Market Value</h5>
                      <p>{props.items['Daily Market Value'][0]?parseFloat(props.items['Daily Market Value'][0]).toFixed(4):''}</p>
                    </div>
                  </div>
                </div>
                <div className="third_rw">
                  <div className="row g-4 summary_lft_txt mt-1">
                    <div className="col-lg-4">
                      <h5> Total Annual Fund Operating Expenses After Fee Waiver</h5>
                      <p>{props.items['Total Annual Fund Operating Expenses After Fee Waiver'][0]?props.items['Total Annual Fund Operating Expenses After Fee Waiver'][0]:''}</p>
                    </div>
                    <div className="col-lg-4">
                      <h5> NAV per Share as of Previous Close </h5>
                      <p>{props.items['NAV per share as of Previous Close'][0]?parseFloat(props.items['NAV per share as of Previous Close'][0]).toFixed(2):''}</p>
                    </div>
                    <div className="col-lg-4">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex">
              <div className="info">
                <h3>FUND MANAGERS</h3>
                <div className="info-item d-flex">
                  <div className="row g-4 g-4 d-flex justify-content-center align-items-center">
                    <div className="col-lg-4">
                      <img src={avatar1} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-8">
                      <span> Advisor: North Capital, Inc. </span>
                    </div>
                  </div>
                </div>
                <div className="info-item d-flex">
                  <div className="row g-4 g-4 d-flex justify-content-center align-items-center">
                    <div className="col-lg-4">
                      <img src={avatar2} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-8">
                      <span>James P Dowd, CFA, CPA</span>
                    </div>
                  </div>
                </div>
                <div className="info-item d-flex">
                  <div className="row g-4 g-4 d-flex justify-content-center align-items-center">
                    <div className="col-lg-4">
                      <img src={avatar3} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-8">
                      <span>Michael T Weaver Jr, CFA</span>
                    </div>
                  </div>
                </div>
              </div>          
            </div>
          </div>
        </div>
      </section>
    );
}

export default Summary;