// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

function Performance(props) {
  console.log("Performance");
  console.log(props);
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  const timeZones = ["GMT"];
  var d = new Date(props.items["Last Updated.1"][0]);
      return (
        <div>
          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="section-header">
                <h3>PERFORMANCE</h3>
                <p><b>LAST UPDATED</b>
                 <span> {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long',day: '2-digit',timeZone: timeZones}).format(props.items["Last Updated.1"][0])}</span> </p>
              </div>
              <div className="row gy-5">
                <div className="col-lg-12">
                  <div className="summary_detail pt-5">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">NAME</th>
                          <th scope="col">1YR</th>
                          <th scope="col">3YR</th>
                          <th scope="col">5YR</th>
                          <th scope="col">10YR</th>
                          <th scope="col">SINCE INCEPTION (ANNUALIZED)</th>
                          <th scope="col">INCEPTION DATE</th>
                        </tr>
                      </thead>
                      <tbody>
                        { 
                          Object.keys(props.items["Name"]).map((oneKey,i)=>{
                            return (
                              <tr>
                                <td>{props.items["Name"][oneKey]?props.items["Name"][oneKey]:''}</td>
                                <td>{props.items["1yr"][oneKey]?props.items["1yr"][oneKey]:''}</td>
                                <td>{props.items["3yr"][oneKey]?props.items["3yr"][oneKey]:''}</td>
                                <td>{props.items["5yr"][oneKey]?props.items["5yr"][oneKey]:''}</td>
                                <td>{props.items["10yr"][oneKey]?props.items["10yr"][oneKey]:''}</td>
                                <td>{props.items["Since Inception (Annualized)"][oneKey]?props.items["Since Inception (Annualized)"][oneKey]:''}</td>
                                <td>{props.items["Inception Date"][oneKey]?props.items["Inception Date"][oneKey]:''}</td>
                              </tr>
                              )
                          })
                      }
                      </tbody>
                    </table>
                  </div>
                </div>     
              </div>
              <div className="row g-4 g-lg-5 mt-3 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>          
                <div className="col-lg-12 ">
                  {/* Tab Content */}
                  <div className="tab-content">
                    <div className="tab-pane fade show active">
                      <span>*Rate for 3-month treasury bills in secondary market transactions. Data provided by the Federal Reserve Bank of St. Louis</span>
                      <div className="d-flex mt-4">
                        <i className="bi bi-check2" />
                        <p>Performance data quoted represents past performance; past performance does not guarantee future results. The investment return will fluctuate so that an investor’s shares, when redeemed, may be worth more or less than their original cost. Current performance of the Fund may be lower or higher than the performance quoted. Performance data current to the most recent month end may be obtained by calling 833-2-NCFUND (833-262-3863).</p><p>
                        </p></div>
                      <h3>PRINCIPAL RISKS</h3>
                      <div className="d-flex mt-1">                 
                        <i className="bi bi-check2" />
                        <p>You could lose money by investing in the Fund. Although the Fund seeks to preserve the value of your investment at $1.00 per share, it cannot guarantee it will do so. An investment in the Fund is not a bank deposit and is not insured or guaranteed by the Federal Deposit Insurance Corporation or any other government agency. The Adviser has no legal obligation to provide financial support to the Fund, and you should not expect that the Adviser will provide financial support to the Fund at any time. The Fund should not be relied upon as a complete investment program. There can be no assurance that the Fund will achieve its investment objective. The Fund is subject to the principal risks summarized below, and these risks are more fully described in the section “Additional Information About Principal Investment Strategies and Related Risks” in the Prospectus.</p><p>
                        </p></div>
                      <div className="d-flex mt-1">                 
                        <i className="bi bi-check2" />
                        <p>Stable NAV Risk. The Fund may not be able to maintain a stable $1.00 share price at all times. If any money market fund that intends to maintain a stable NAV fails to do so (or if there is a perceived threat of such a failure), other such money market funds, including the Fund, could be subject to increased redemption activity, which could adversely affect the Fund’s NAV. In the unlikely event that the Board were to determine, pursuant to Rule 2a-7, that the extent of the deviation between the Fund’s amortized cost per Share and its market-based NAV per Share may result in material dilution or other unfair results to shareholders, the Board will cause the Fund to take such action as it deems appropriate to eliminate or reduce to the extent practicable such dilution or unfair results.</p><p>
                        </p></div>
                      <div className="d-flex mt-1">                 
                        <i className="bi bi-check2" />
                        <p>For a full discussion of the risks please review the prospectus</p><p>
                        </p></div>
                    </div>{/* End Tab 1 Content */}             
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="cta" className="cta">
            <div className="container aos-init aos-animate" data-aos="zoom-out">
              <div className="row g-5">
                <div className="col-lg-12 col-md-12 content justify-content-center order-last order-md-first text-center">
                  <h3>PORTFOLIO</h3>
                  <a className="cta-btn align-items-center mt-2 mb-4" href="https://hubs.ly/H0k4xXW0">Monthly Listing of Portfolio Holdings</a>
                  <p>             
                    To obtain the most recent 12 months of publicly available information filed by the fund, click the button below
                  </p>
                  <a className="cta-btn align-self-start" href="https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&CIK=0001761511&owner=exclude&count=40&hidefilings=0">View Most Recent Information</a>
                </div>
                <div className="content d-flex justify-content-center align-items-center text-center">
                </div>
              </div>
            </div>
          </section>
        </div>
    );
  return (
    <div>
      <Card>
        <Card.Header as="h5">PERFORMANCE</Card.Header>
        <Card.Body>
          <Container>
            <Row>
              <Col>
                <p><b>LAST UPDATED</b></p>
                <p>{props.items["Last Updated"][0]?new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(props.items["Last Updated"][0]):''}</p>
              </Col>
            </Row>
            <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>1YR</th>
          <th>3YR</th>
          <th>5YR</th>
          <th>10YR</th>
          <th>SINCE INCEPTION (ANNUALIZED)</th>
          <th>INCEPTION DATE</th>
        </tr>
      </thead>
      <tbody>
      { 
        Object.keys(props.items["Name"]).map((oneKey,i)=>{
            return (
              <tr>
                <td>{props.items["Name"][oneKey]?props.items["Name"][oneKey]:''}</td>
                <td>{props.items["1yr"][oneKey]?props.items["1yr"][oneKey]:''}</td>
                <td>{props.items["3yr"][oneKey]?props.items["3yr"][oneKey]:''}</td>
                <td>{props.items["5yr"][oneKey]?props.items["5yr"][oneKey]:''}</td>
                <td>{props.items["10yr"][oneKey]?props.items["10yr"][oneKey]:''}</td>
                <td>{props.items["Since Inception (Annualized)"][oneKey]?props.items["Since Inception (Annualized)"][oneKey]:''}</td>
                <td>{props.items["Inception Date"][oneKey]?props.items["Inception Date"][oneKey]:''}</td>
              </tr>
              )
          })
      }
        
      </tbody>
    </Table>
          </Container>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Performance;