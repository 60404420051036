function Disclosures() {
      return (
      <div>
        <section id="about" className="about">
          <div className="container aos-init aos-animate" data-aos="fade-up">
            <div className="row g-4 g-lg-5 bg_dark aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>     
              <div className="col-lg-12">
                <div className="summary_detail">
                  <h3>ADDITIONAL DISCLOSURES</h3>       
                  <div className="mt-1 tab-content">                  
                    <p> Investors should carefully consider the fund’s investment objectives, risks, charges and expenses before investing. The prospectus contains this and other information; click <a style={{color:"rgb(4, 217, 255)"}} href="https://app.hubspot.com/documents/4042879/view/48701570?accessId=03ca22">here</a> to view the prospectus. Please read it carefully before investing</p>
                    <p> The funds referred to in this website may be offered only to persons in the United States. This website should not be considered a solicitation or offering of shares of the funds outside the United States.</p>
                    <p> The North Capital Treasury Money Market Fund is distributed by North Capital Private Securities Corporation.</p>
                    <p> It is not possible to invest directly in an index.</p>
                    <p> Fund holdings are subject to change at any time and should not be considered recommendations to buy or sell any security.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="featured-services" className="featured-services">
          <div className="container">
            <div className="row gy-4">
              <div className="col-xl-4 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <h4>ALL ACCOUNTS </h4>
                  <p><a href="https://funds.northcapital.com/open-an-account/">Open An Account</a></p>
                  <p><a href="https://funds.northcapital.com/all-accounts/">Learn More</a></p>
                </div>
              </div>{/* End Service Item */}
              <div className="col-xl-4 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <h4>BROKER DEALER SERVICES </h4>
                  <p><a href="https://funds.northcapital.com/broker-dealer-services/#partnerships">Partnerships</a></p>
                  <p><a href="https://funds.northcapital.com/broker-dealer-services/#private">Private Funds</a></p>
                  <p><a href="https://funds.northcapital.com/broker-dealer-services/#issuers">Issuers</a></p>
                  <p><a href="https://funds.northcapital.com/broker-dealer-services/">Learn More</a></p>
                </div>
              </div>{/* End Service Item */}
              <div className="col-xl-4 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <h4>INVESTMENT TECHNOLOGY</h4>
                  <p><a href="https://funds.northcapital.com/kyc-aml/">KYC/AML</a></p>
                  <p><a href="https://funds.northcapital.com/accreditation-verification/">Accreditation Verification</a></p>
                  <p><a href="https://funds.northcapital.com/technology/#payment">Payment Processing</a></p>
                  <p><a href="https://funds.northcapital.com/technology/">Learn More</a></p>
                  <p><a href="https://funds.northcapital.com/schedule-a-demo/" rel="noopener noreferrer" id="schedule" className="schedule pum-trigger" style={{cursor: 'pointer'}}>Schedule a Demo</a></p>
                </div>
              </div>{/* End Service Item */}
              <div className="col-xl-4 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <h4>LEGAL DOCUMENTS</h4>
                  <p><a href="https://hubs.ly/H0xn6p10" rel="noopener noreferrer">Terms of Use</a></p>
                  <p><a href="https://hubs.ly/H0nTzdx0" rel="noopener noreferrer">Privacy Policy</a></p>
                  <p><a href="https://hubs.ly/H0phCs60" rel="noopener noreferrer">Supplemental Privacy Notice for California Residents</a></p>
                  <p><a href="https://funds.northcapital.com/library/#disclosure" rel="noopener noreferrer">Code of Ethics</a></p>
                  <p><a href="https://funds.northcapital.com/library/#disclosure" rel="noopener noreferrer">Disclosure Documents</a></p>
                  <p><a href="https://hubs.ly/H0sLQtR0" rel="noopener noreferrer">Disclosure Statement on Business Continuity Planning</a></p>
                  <p><a href="https://hubs.ly/H0yXHV40" rel="noopener noreferrer">Important Update – Form CRS</a></p>
                </div>{/* End Service Item */}
              </div>
              <div className="col-xl-4 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <h4>ESCROW SERVICES</h4>
                  <p><a href="https://funds.northcapital.com/escrow-services/">Registered Funding Portals</a></p>
                  <p><a href="https://funds.northcapital.com/contact-us-escrow/">Open an Escrow Account</a></p>
                  <p><a href="https://funds.northcapital.com/escrow-services/">Learn More</a></p>
                </div>
              </div>{/* End Service Item */}
              <div className="col-xl-4 col-md-6 d-flex">
                <div className="service-item position-relative">
                  <h4>FOR MORE INFORMATION</h4>
                  <p><a href="https://support.northcapital.com/knowledge">View Our Knowledge Base</a></p>
                  <p><a href="https://funds.northcapital.com/resources/">View Our Resources</a></p>
                  <p><a href="https://funds.northcapital.com/blog" rel="noopener noreferrer">View Our Blog</a></p>
                  <p><a href="https://twitter.com/norcap" rel="noopener noreferrer">Follow Us on Twitter</a></p>
                  <p><a href="https://www.linkedin.com/company/north-capital-private-securities-corporation/" rel="noopener noreferrer">Find Us on LinkedIn</a></p>
                </div>
              </div>{/* End Service Item */}
            </div>
          </div>
        </section>
      </div>
    );
}

export default Disclosures;