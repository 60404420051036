// import Button from 'react-bootstrap/Button';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

function Distribution(props) {
  console.log(props)
  console.log(Object.keys(props.items))
  const timeZones = ["GMT"];
  // console.log(props.distribution["Distribution\nType\n "][0])
  return (

      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h3>DAILY MARKET VALUE, LIQUID ASSETS AND NET CASH FLOWS</h3>
             <a href="/nctmmf"> <button className="cus_btn"> Open Table</button></a>
          </div>
          <div className="row gy-5">
            <div className="col-lg-12">
              <div className="summary_detail pt-5">
                <p><b> Management Fee:</b> <span> 0.25% </span> </p>
                {/* <p><b> Other Expenses ¹ :</b> <span> 178.48% </span> </p> */}
                <p><b> Other Expenses ¹ :</b> <span> 1.76% </span> </p>
                <p><b> Total Annual Fund Operating Expenses:</b> <span> 2.01% </span> </p>
                {/* <p><b> Total Annual Fund Operating Expenses:</b> <span> 178.73% </span> </p> */}
                <p><b> Fee Waiver ² :</b> <span> 2.01% </span> </p>
                {/* <p><b> Fee Waiver ² :</b> <span> 178.73% </span> </p> */}
                <p><b> Total Annual Fund Operating Expenses After Fee Waiver ³ : </b> <span> 0.00% </span> </p>
                {/* <p><b> Total Annual Fund Operating Expenses After Fee Waiver: ³ </b> <span> {props.items['Total Annual Fund Operating Expenses After Fee Waiver'][0]?props.items["Total Annual Fund Operating Expenses After Fee Waiver"][0]:''} </span> </p> */}
                <p><b> DISTRIBUTION HISTORY</b> </p>
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">DISTRIBUTION TYPE</th>
                      <th scope="col">PER SHARE DISTRIBUTION</th>
                      <th scope="col">RECORD DATE</th>
                      <th scope="col">REINVEST DATE</th>
                      <th scope="col">PAYABLE DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    { 
                      Object.keys(props.distribution["Distribution\nType\n "]).map((distributionKey,i)=>{
                          return (
                            <tr>
                              <td>{props.distribution["Distribution\nType\n "][distributionKey]?props.distribution["Distribution\nType\n "][distributionKey]:''}</td>
                              <td>{props.distribution["Per Share \nDistribution\n "][distributionKey]?props.distribution["Per Share \nDistribution\n "][distributionKey]:''}</td>
                              <td>{props.distribution["Record \nDate\n "][distributionKey]?new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',timeZone: timeZones}).format(props.distribution["Record \nDate\n "][distributionKey]):''}</td>
                              <td>{props.distribution["Reinvest \nDate\n "][distributionKey]?new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',timeZone: timeZones}).format(props.distribution["Reinvest \nDate\n "][distributionKey]):''}</td>
                              <td>{props.distribution["Payable \nDate\n "][distributionKey]?new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',timeZone: timeZones}).format(props.distribution["Payable \nDate\n "][distributionKey]):''}</td>
                            </tr>
                            )
                        })
                    }             
                  </tbody>
                </table>
                <p>
                  ¹ Based on estimates for the Fund’s current fiscal year.
                </p>
                <p>
                  ² North Capital, Inc., the Fund’s investment adviser (the “Adviser”) has contractually agreed to waive its fees and reimburse expenses of the Fund to ensure that Total Annual Fund Operating Expenses After Fee Waiver and Reimbursement (exclusive of (i) any front-end or contingent deferred loads; (ii) brokerage fees and commissions; (iii) unaffiliated acquired fund fees and expenses; (iv) fees and expenses associated with investments in other collective investment vehicles or derivative instruments (including for example option and swap fees and expenses); (v) borrowing costs (such as interest and dividend expense on securities sold short) (vi) taxes; (vii) extraordinary expenses such as litigation expenses (which may include indemnification of Fund officers and Trustees, and contractual indemnification of Fund service providers, including the Adviser)) will not exceed (i) 0.45% of average daily net assets of Institutional Class shares until at least August 31, 2023 and (ii) 1.00% of average daily net assets of Institutional Class Shares until at least August 31, 2031. These fee waivers and expense reimbursements are subject to possible recoupment from the Fund within the three years after the fees have been waived or reimbursed, if there are waived amounts that have not been recouped outstanding and such recoupment, after giving effect to the recouped amounts, can be achieved within the lower of the foregoing expense limits and the expense limits in place at the time of recoupment. This agreement may be terminated only by the Board of Trustees (the “Board”) of North Capital Funds Trust (the “Trust”), of which the Fund is a series, on 60 days’ written notice to the Adviser.
                </p>
                <p>
                  ³ Total Annual fund operating expenses after fee waiver represents the percentage paid by investors. The Adviser started voluntarily waiving fees to a cap of 0.00% starting on April 6, 2020 indefinitely.
                </p>
              </div>
            </div>     
          </div>
        </div>    
      </section>
    );
}

export default Distribution;